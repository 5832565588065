<template>
  <v-app>
    <v-app-bar
      app
      color="black"
      dark
    >
    <v-img
          alt="Logo"
          class="shrink mr-2"
          contain
          :src="require('@/assets/logo.png')"
          style="max-width: 60px;"
        />
      Lobos Hermanos
    </v-app-bar>

    <v-main class="fondo">
      <v-row class="d-flex justify-center">
        <v-col cols="12" md="4" class="">
          <router-view/>
        </v-col>
      </v-row>
    </v-main>
  </v-app>
</template>

<script>

export default {
  name: 'App',

  data: () => ({
    //
  }),
};
</script>
<style>
.fondo {
  background: linear-gradient(
    0deg,
    rgba(155, 188, 83, 1) 0%,
    /* Verde manzana hacia la parte inferior */ rgba(204, 220, 57, 1) 25%,
    /* Amarillo pastel */ rgba(126, 211, 33, 1) 50%,
    /* Verde saturado */ rgba(167, 219, 45, 1) 75%,
    /* Toques de amarillo dorado */ rgba(178, 225, 125, 1) 100%
      /* Verde lima claro en la parte superior */
  );
}
</style>
