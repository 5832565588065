<template>
  <v-app class="fondo">
    <v-main class="fondo">
      <v-container class="d-flex flex-column">
        <v-img
          alt="Logo"
          class="shrink mx-auto"
          contain
          :src="require('@/assets/logo.png')"
          style="max-width: 260px"
        >
        </v-img>
        <h1 class="mx-auto">¡Crea tu pedido!</h1>
        <strong class="order-summary"> Tu pedido: {{ orderSummary }}</strong>
        <v-btn color="green" dark @click="sendOrder" class="mx-auto my-3" x-large>
          <v-icon left>mdi-whatsapp</v-icon>
          Enviar Pedido por WhatsApp
        </v-btn>
      </v-container>
        <!-- Acordeón para Agua y Crema -->
        <v-expansion-panels>
          <v-expansion-panel
            v-for="category in categories"
            :key="category.title"
          >
            <v-expansion-panel-header>{{
              category.title
            }}</v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-card outlined>
                <v-list>
                  <v-list-item-group>
                    <v-list-item
                      v-for="(item, index) in category.items"
                      :key="index"
                    >
                      <v-icon>mdi-ice-pop</v-icon>{{ item.name }}
                      <v-spacer />
                      <v-btn icon @click="decrement(category.title, index)">
                        <v-icon>mdi-minus</v-icon>
                      </v-btn>
                      <span class="mx-2">{{ item.count }}</span>
                      <v-btn icon @click="increment(category.title, index)">
                        <v-icon>mdi-plus</v-icon>
                      </v-btn>
                      
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-card>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
    </v-main>
  </v-app>
</template>

<script>
// @ is an alias to /src

export default {
  name: "Home",
  components: {},
  computed: {
    categoryTotals() {
      return this.categories.map((category) => {
        const total = category.items.reduce((sum, item) => sum + item.count, 0);
        return { title: category.title, total };
      });
    },
    orderSummary() {
      let summary = "";
      this.categories.forEach((category, index) => {
        let itemsSummary = category.items
          .filter((item) => item.count > 0)
          .map((item) => `${item.count} ${item.name}`)
          .join(", ");
        if (itemsSummary) {
          summary += `\n${category.title}: ${itemsSummary}`;
          if (index < this.categories.length - 1) {
            summary += " y ";
          }
        }
      });
      // Remueve el último " y " si es que existe
      summary = summary.trim().replace(/ y $/, "");
      return summary;
    },
  },
  methods: {
    increment(categoryTitle, index) {
      const category = this.categories.find((c) => c.title === categoryTitle);
      if (category) {
        category.items[index].count++;
      }
    },
    decrement(categoryTitle, index) {
      const category = this.categories.find((c) => c.title === categoryTitle);
      if (category && category.items[index].count > 0) {
        category.items[index].count--;
      }
    },
    sendOrder() {
      const baseURL = "https://wa.me/"; // URL base de WhatsApp
      const phoneNumber = "+5215569129221"; // Tu número de teléfono en formato internacional sin '+'
      const message = encodeURIComponent(
        "Quiero hacer el siguiente pedido: " + this.orderSummary
      );
      const whatsappURL = `${baseURL}${phoneNumber}?text=${message}`;
      if (this.orderSummary) {
        window.open(whatsappURL, "_blank");
      }
    },
  },
  data() {
    return {
      items: [
        { name: "Limón", count: 0 },
        { name: "Tamarindo", count: 0 },
        { name: "Guayaba", count: 0 },
        // Agrega más sabores según necesites
      ],
      categories: [
        {
          title: "Paletas Agua",
          items: [
            { name: "Limón", count: 0 },
            { name: "Tamarindo", count: 0 },
            { name: "Guayaba", count: 0 },
            { name: "Fresa", count: 0 },
            { name: "Guanábana", count: 0 },
            { name: "Mango", count: 0 },
            { name: "Melón", count: 0 },
            { name: "Piña", count: 0 },
            { name: "Sandía", count: 0 },
            { name: "Naranja", count: 0 },
          ],
        },
        {
          title: "Paletas Crema",
          items: [
            { name: "Chocolate", count: 0 },
            { name: "Vainilla", count: 0 },
            { name: "Fresa", count: 0 },
            { name: "Nuez", count: 0 },
            { name: "Piña Colada", count: 0 },
            { name: "Coco", count: 0 },
            { name: "Mango", count: 0 },
            { name: "Rompopo", count: 0 },
            { name: "Tutifruti", count: 0 },
            { name: "Uva", count: 0 },
          ],
        },
      ],
    };
  },
};
</script>

<style>
.fondo {
  background: linear-gradient(
    0deg,
    rgba(155, 188, 83, 1) 0%,
    /* Verde manzana hacia la parte inferior */ rgba(204, 220, 57, 1) 25%,
    /* Amarillo pastel */ rgba(126, 211, 33, 1) 50%,
    /* Verde saturado */ rgba(167, 219, 45, 1) 75%,
    /* Toques de amarillo dorado */ rgba(178, 225, 125, 1) 100%
      /* Verde lima claro en la parte superior */
  );
}
.order-summary {
  background-color: #f0f4c3; /* Un verde claro para el fondo */
  border: 2px solid #cddc39; /* Un verde más oscuro para el borde */
  padding: 20px;
  margin: 20px 0;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Sombra suave para profundidad */
  font-family: Arial, sans-serif;
}
</style>
